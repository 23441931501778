<template>
    <div
        class="wrapper"
        :class="getRootClasses"
    >
        <IconSearch
            v-if="view === 'primary' && isSearch"
            class="wrapper__searh-icon"
        />
        <input
            type="text"
            class="input"
            ref="input"
            :value="value"
            v-bind="$attrs"
            :style="styleObj"
            :placeholder="placeholder"
            :readonly="isReadOnly"
            v-on:keyup.enter="onBlur"
            @input="onInput"
            @blur="onBlur"
        >
        <span 
            v-if="view === 'outline' && label" 
            class="input-label"
            :style="inputLabelStyleObj"
        >
            {{ label }}
        </span>
        <IconCloseV2
            v-if="isClear"
            class="clear"
            @click.native="onClear"
        />
        <!-- <div
            v-if="isClear"
            class="clear icon-tabler-icon-trash"
            @click="onClear"
        /> -->
        <!-- Был символ крестик - &#x2715;-->
        <div v-if="!isValid" class="input-error-msg">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script>
import IconSearch from '../Icons/IconSearch.vue';
import IconCloseV2 from '../Icons/IconCloseV2.vue';
export default {
    name: "BaseInput",
    components: {
        IconSearch,
        IconCloseV2
    },
    props: {
        modificator: {
            type: String,
            default: '',
        },
        value: {
            type: String
        },
        canClear: {
            type: Boolean,
            default: true
        },
        view: {
            type: String,
            default: 'line',
            validator: value => ['base', 'line', 'primary', 'table', 'outline'].includes(value)
        },
        styleObj: {
            type: Object,
            default: () => ({})
        },
        label: {
          type: String,
          default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        isReadOnly: {
            type: Boolean,
            default: false
        },
        delayInputLazy: {
            type: Number,
            default: 900
        },
        isSearch: {
            type: Boolean,
            default: false
        },
        isValid: {
            type: Boolean,
            default: true
        },
        errorMessage: {
            type: String,
            default: ''
        }
    },
    data: () => ({
        inputLazyTimerId: null
    }),
    computed: {
        /**
         * Условие пустоты значения
         * @returns {Boolean}
         */
        isEmpty() {
            return this.value === '' || this.value === undefined;
        },
        /**
         * Условие рендера крестика очистки инпута
         * @returns {Boolean}
         */
        isClear() {
            return this.canClear && this.value !== '' && this.value !== undefined;
        },
        /**
         * Классы для корневого элемента
         */
        getRootClasses() {
            const classes = ['wrapper_' + this.view];

            if (this.modificator) {
                classes.push(`wrapper--${this.modificator}`);
            }

            if (!this.isValid) classes.push(`wrapper_error`);

            if (!this.isEmpty) classes.push('wrapper--filled');

            return classes;
        },
        inputLabelStyleObj() {
            return this.value !== '' ? {'top': 0, 'font-size': '12px'} : {};
        }
    },
    methods: {
        onInput(event) {
            const value = event === null ? '' : event.target.value
            this.$emit('input', value)
            //TODO: при событии ввода вызываю событие лениового ввода, которое либо дропнет предудыщее, либо выполнится
            this.onInputLazy(value);
        },
        /**
         * Событие ленивого ввода
         * @param {String} value - значение инпута
         */
        onInputLazy(value = '') {
            if (this.inputLazyTimerId !== null) {
                clearTimeout(this.inputLazyTimerId);
                this.inputLazyTimerId = null;
            }
            this.inputLazyTimerId = setTimeout(() => {
                this.$emit('onInputLazy', value);
                this.$emit('update:value', value);
            }, this.delayInputLazy);
        },
        onClear() {
            this.$refs.input.value = ''
            this.onInput(null)
        },
        /**
         * Событие для фокусировки инпута
         */
        onFocus() {
            this.$refs.input.focus();
        },
        /**
         * Событие снятия фокусировки с инпута
         */
        onBlur(event) {
            this.$emit('onBlur', event);
        }
    }
}
</script>

<style lang="scss" scoped>
$primary-color: 			#F0F3F8 !default;
$primary-lighten-color: 	#F9FAFB !default;
$primary-disabled-color: 	#DCE1E8 !default;
$assistant-color: 			#ADB0BB !default;
$active-color: 				#4A92F6 !default;
$active-darker-color: 		#1f79d2 !default;
$danger-color: 				#F84967 !default;
$success-color: 			#6EC87A !default;
$success-lighten-color: 	#C5E9CA !default;
$reserved-color:			#FFB300 !default;
$bg-input-color:            #F5F5F5;
$input-color:               #000000;
$placeholder-color:         #C8CAD1;

$color-text--outline: #000000 !default;
$color-background--outline: inherit !default;
$color-border--outline: #C6CDDC !default;
$color-border--outline-focus: #2B6BF3 !default;
$color-border--outline-error: $danger-color !default;

.input-error-msg {
    position: absolute;
    color: $danger-color;
    bottom: -20px;
}

.input-label {
    cursor: text;
    pointer-events: none;
    padding: 4px;
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    color: $color-text--outline;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    background-color: inherit;
    transition: all 0.15s ease-in-out;
}

.wrapper {
    position: relative;
    display: block;
    width: 100%;

    &_error {
        border-radius: 10px;
        border: 1px solid $danger-color;
    }

    &__searh-icon {
        width: 15px;
        height: 15px;
        cursor: pointer;

        ::v-deep path {
            fill: #949BA3;
        }
    }

    .clear {
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        cursor: pointer;
        line-height: 1.5;
        font-weight: 700;
        font-size: 14px;
        color: #ADB0BB;

        &:hover {
            color: red;
        }
    }

    &_base {
        .input {
            display: block;
            width: 100%;
            padding: 0 .75rem;
            font-size: 1rem;
            line-height: 1.5;
            color: #495057;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #ced4da;
            border-radius: .25rem;
            transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

            &:focus {
                color: #495057;
                background-color: #fff;
                border-color: #80bdff;
                outline: 0;
                box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25);
            }
            &::placeholder {
                color: $placeholder-color;
            }
        }
    }

    &_line {
        .input {
            display: block;
            width: 100%;
            font-size: 1rem;
            line-height: 1.5;
            background-color: #fff;
            border: none;
            border-bottom: 1px solid;
            border-color: #E7E7EB;
            background: none;

            &:focus {
                outline: none;
                border-color: #000;
                color: #000;
            }
        }
    }

    &_primary {
        display: flex;
        align-items: center;
        background: #F4F5F5;
        border-radius: 10px;
        padding: 0 15px;

		.input {
            // background: $primary-color;
            border: none;
            outline: none;
            height: 36px;
            width: 100%;
            display: block;
            // padding-left: 15px;
            font-weight: 700;
            font-size: 12px;
            color: #000;
            // border-radius: 4px;

            padding: 6px 15px;
            background: #F4F5F5;
            border-radius: 10px;

            &::placeholder {
                // color: $placeholder-color;
                color: #949BA3;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
            }
        }
    }

    &_table {
        .input {
            display: block;
            width: 100%;
            font-size: 12px;
            line-height: 14px;
            background: #fff;
            border: none;

            padding: 5px 15px;
            padding-right: 30px;

            &:focus {
                background: #f2f2f2;
                border-color: transparent;
                outline: none;
                color: #6EC87A;
            }
        }
    }

    &_outline {
        &.wrapper_error {
            border: none;
            .input {
                border-color: $color-border--outline-error;
            }
        }
        .input {
            padding: 8px 20px;
            width: 100%;
            min-height: 54px;
            outline: none;
            color: $color-text--outline;
            font-size: 14px;
            font-weight: 400;
            line-height: 1;
            background-color: $color-background--outline;
            border: 1px solid $color-border--outline;
            border-radius: 16px;
            transition: border 0.15s ease-in-out;
            &:focus {
                border-color: $color-border--outline-focus;
            }
            &:-webkit-autofill,
            &:-webkit-autofill:focus {
                transition: background-color 600000s 0s, color 600000s 0s, border-color 0.15s ease-in-out;
            }
            &[data-autocompleted] {
                background-color: transparent !important;
            }
        }

        .clear {
            right: 20px;
            color: $color-border--outline;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.5;
            transition: color 0.15s ease-in-out;
            &:hover {
                color: $color-text--outline;
            }
        }
    }

    &--filled .input-label,
    .input:focus + .input-label {
        top: 0;
        font-size: 12px;
    }


    //&--login {
    //    .input {
    //        height: 44px;
    //        font-size: 14px;
    //        font-weight: 400;
    //        color: $input-color;
    //        background: $bg-input-color;
    //        box-sizing: border-box;
    //    }
    //}
}
</style>
