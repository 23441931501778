<template>
	<div
        ref="table"
        class="table"
        @scroll="onScroll"
    >
        <div
            ref="header"
            class="header"
        >
            <!-- Заголовки таблицы -->
            <div  v-if="columns.length !== 0" class="row titles">
                <BaseCheckboxV3
                    v-if="canCheck && !isRenderingTable"
                    :class="['cell cell-checkbox', {isSticky: isStickyCheckbox}]"
                    :size="24"
                    v-model="checkAll"
                    @change.native="onCheckAll"
                />
                <div
                    v-for="(column, posX) of columns"
                    :key="posX"
                    :style="cellStyles(column)"
                    class="cell"
                    :class="getCellClasses(column.prop, column.classes)"
                    @click="onSort(column)"
                >
                    <div
                        v-if="column.isSlotHeader"
                        class="cell__content"
                    >
                        <slot
                            :name="`header-${column.prop}`"
                            :column="column"
                        />
                    </div>
                    <div
                        v-else
                        class="cell__content"
                    >
                        <span>{{column.label}}</span>
                        <svg
                            v-show="column.prop === sortedKey"
                            class="cell__arrow"
                            :class="{'cell__arrow_rotate': isReverse}"
                            xmlns="http://www.w3.org/2000/svg"
                            width="7.918"
                            height="7.918"
                            viewBox="0 0 7.918 7.918"
                            fill="currentColor"
                        ><g id="Next" transform="translate(7.918) rotate(90)"><path id="path" d="M3.959,0,3.266.693,6.038,3.464H0v.99H6.038L3.266,7.226l.693.693L7.918,3.959Z" transform="translate(0 0)" fill-rule="evenodd"/></g></svg>
                    </div>
                </div>
            </div>
            <!-- Заголовки таблицы -->
            <!-- Строка поиска -->
            <div
                v-if="hasSearchRow && columns.length !== 0"
                class="row search"
            >
                <div v-if="canCheck && !isRenderingTable" :class="['cell cell-checkbox', {isSticky: isStickyCheckbox}]"></div>
                <div
                    v-for="(column, posX) of columns"
                    :key="posX"
                    :style="cellSearchStyles(column)"
                    class="cell cell-search"
                    :class="getCellClasses(column.prop, column.classes)"
                >
                    <!--TODO: сделал фильтрацию таблицы по событию ленивого кода, убрал событие обработки клика по энтеру-->
                    <BaseInput
                        v-if="column.canSearch"
                        v-model="searchValues[column.prop]"
                        class="cell__search-input"
                        view="table"
                        placeholder="Найти"
                        :canClear="true"
                        @onInputLazy="updateSearchValues"
                    />
                </div>
            </div>
            <!-- Строка поиска -->
        </div>
        <ProgressBar
            :isLoading="isProgressBar"
            :style="styleProgressBar"
            class="table__progressbar"
            ref="progressbar"
        />

        <div class="body" :style="getTableBodyStyle">
            <div
                v-if="rows.length === 0 && !isProgressBar"
                class="body__empty"
            >
                Ничего не найдено по заданным фильтрам
            </div>
            <div
                v-for="(row, posY) of rows"
                :key="posY"
                :data-rowid="posY"
                class="row"
                :class="{'row__no-margin': !isRowMargin}"
                :style="getRowsStyle"
            >
                <BaseCheckboxV3
                    v-if="canCheck && !isRenderingTable"
                    :class="['cell cell-checkbox', {isSticky: isStickyCheckbox}]"
                    :value="row[primaryKey]"
                    :isPartChecked="isRowSelectedPart(row[primaryKey])"
                    :size="21"
                    v-model="selectedCheckboxes"
                    @change.native="onCheckRow({row})"
                />

                <div
                    v-for="(column, posX) of columns"
                    :key="posX"
                    :style="cellStyles(column, row)"
                    class="cell"
                    :class="getCellClasses(column.prop, column.classes)"
                    @click="onClickCell(column, row)"
                    :draggable="isDraggable(column)"
                    @dragstart="onDragStart(posY)"
                    @dragover.prevent
                    @drop="onDrop(posY)"
                >
                    <slot
                        v-if="column.isSlot"
                        :name="column.prop"
                        :cell="row[column.prop]"
                        :row="row"
                        :column="column"
                    />
                    <div
                        v-else
                        class="cell__content"
                    >{{row[column.prop]}}</div>
                </div>
            </div>

            <!-- <div
                v-if="isLoading && isProgressBar"
                class="table__preloader"
                ref="preloader"
            >
                <BasePreloader :size="30" />
            </div> -->
        </div>
	</div>
</template>

<script>
import BaseCheckboxV3 from "../Base/BaseCheckboxV3";
// import BasePreloader from "../Base/BasePreloader"
import BaseInput from '../Base/BaseTextInput'
import ProgressBar from '../Base/ProgressBar'

import lazyload from './lazyload'
import search from './search'
import sort from './sort'
import checkRow from './checkRow'

export default {
    name: "Table",
    props: {
        columns: {
            type: Array,
            required: true
        },
        rows: {
            type: Array,
            // required: true
            default: () => []
        },
        rowsStyles: {
            type: Object,
            default: () => ({})
        },
        isProgressBar: {
            type: Boolean,
            default: false
        },
        isRenderingTable: {
            type: Boolean,
            default: false
        },
        primaryKey: {
            type: String,
            required: true
        },
        canCheck: {
            type: Boolean,
            default: false
        },
        canCheckAll: {
            type: Boolean,
            default: true
        },
        isRowMargin: {
            type: Boolean,
            default: true
        },
        virtualScrollConfig: {
            type: Object,
            default: () => ({}),
        },
        isStickyProgressBar: {
            type: Boolean,
            default: false
        },
        isStickyCheckbox: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            draggedRowIndex: null,
        }
    },
    mixins: [lazyload, search, sort, checkRow],
    components: {
        BaseCheckboxV3,
        // BasePreloader,
        BaseInput,
        ProgressBar
    },
    mounted() {
        /* задаю paddingTop тк header - position: absolute */
        // const headerHeight = this.$refs.header.offsetHeight + 'px'
        // this.$refs.table.style.paddingTop = headerHeight
    },
    computed: {
        getRowsStyle() {
            const { rowHeight = 0 } = this.virtualScrollConfig;
            if (rowHeight) {
                return { 'height': rowHeight + 'px' }
            }

            return {};
        },
        getTableBodyStyle() {
            const { spacerStyle = {} } = this.virtualScrollConfig;

            if (Object.entries(spacerStyle).length !== 0) {
                return spacerStyle;
            }

            return {};
        },
        styleProgressBar() {
            const {header = {}} = this.$refs;
            const {offsetHeight = 0} = header;
            const styleProgressBarObj = {
                position: 'sticky',
                top: offsetHeight === 0 ? 0 : `${offsetHeight}px`,
                left: '0px'
            }
            const styleProgressBar = this.isStickyProgressBar && this.isProgressBar ? styleProgressBarObj : {};
            return styleProgressBar;
        }
    },
    methods: {
        isDraggable(column) {
            const {isDraggable = false} = column;
            return isDraggable;
        },

        onDragStart(index) {
            this.draggedRowIndex = index;
        },

        onDrop(index) {
            if (this.draggedRowIndex !== null && this.draggedRowIndex !== index) {
                this.$emit('columnDropped', {
                    from: this.draggedRowIndex,
                    to: index
                });
            }
            this.draggedRowIndex = null;
        },

        getStyleCheckboxAll() {
            const lengthSelected = this.selectedCheckboxes.length;
            const lengthRows = this.rows.length;
            if (lengthSelected !== 0 && lengthRows !== 0 && lengthSelected < lengthRows && lengthSelected > 0)
                return {'background-image': `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='5.995' cy='5.995' r='5.995' fill='%23fff'/%3e%3c/svg%3e"`};
            else
                return {};

        },
        onScroll(event) {
            const leftIndent = this.$refs.table.scrollLeft
            const topIndent = this.$refs.table.scrollTop
            /* Двигаю прогрессбар относительно горизонтального скролла */
            if (this.$refs.progressbar.$el.tagName && !this.isStickyProgressBar) {
                this.$refs.progressbar.$el.style.left = leftIndent + 'px'
                this.$refs.progressbar.$el.style.top = topIndent + this.$refs.header.offsetHeight + 'px'
            }

            /* Двигаю прелоадер */
            if (this.$refs.preloader)
                this.$refs.preloader.style.left = leftIndent + 'px'

            /* Плавующий header */
            // this.$refs.header.style.top = topIndent + 'px'
            this.$emit('onScroll', event);
            this.onLazyLoad(event)
        },
        onClickCell(column, row){
            if(column.isClickEmit){
                this.$emit('onClickCell', {attribute: column.prop, row});
            }
        },
        onCloseSelect() {
            this.$emit('$closeSelect');
        },
        cellStyles(culumnSettings, row = {}) {
            const rowStyle = this.cellStyleRow(row);
            if(culumnSettings.isClickEmit){
                rowStyle['cursor'] = 'pointer';
            }
            return { ...culumnSettings.columnStyles, ...rowStyle};
        },
        cellSearchStyles(columnSettings = {}, row = {}) {
            const cellStyles = columnSettings?.columnsSearchStyle ?? columnSettings.columnStyles;
            const rowStyle = this.cellStyleRow(row);
            if(columnSettings.isClickEmit){
                rowStyle['cursor'] = 'pointer';
            }

            return { ...cellStyles, ...rowStyle};
        },
        cellStyleRow(row = {}) {
            const rowId = String(row[this.primaryKey]);
            const rowStyle = this.rowsStyles[rowId] || {};
            return rowStyle;
        },
        getCellClasses(propName, classes) {
            const result = [
                {'cell-selected': propName === this.sortedKey},
            ]

            if (classes) {
                if (Array.isArray(classes)) {
                    result.push(...classes)
                } else {
                    result.push(classes)
                }
            }

            return result
        }
    }
}
</script>

<style lang="scss" scoped>
$rowBorderRadius: 6px;
$active-color: #4A92F6 !default;
$border-color: #F1F3F9;
$green-color: #71C376;
$text-gray-color: #ADB0BB;
$background-color: #F2F2F2;
$placeholder-color: #EAECED;

::-webkit-scrollbar {
    width: 12px;
}

// ::-webkit-scrollbar-thumb {
//     background-color: #f2f2f2;

//     &:hover {
//         background-color: $active-color;
//     }
//     &:active {
//         background-color: #2c70ce;
//     }
// }

::-webkit-scrollbar:horizontal {
    height: 16px;
}

.table {
    width: 100%;
    max-width: 100%;
    height: 100%;
    position: relative;

    display: flex;
    flex-direction: column;

    background: #fff;
    font-size: 12px;
    overflow: auto;
    border-top: 1px solid $border-color;
    border-left: 1px solid $border-color;

    &__progressbar {
        position: absolute;
        z-index: 2;
    }

    &__preloader {
        position: absolute;
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header {
        position: sticky;
        position:-webkit-sticky;
        top: 0px;
        z-index: 3;
        min-width: 100%;
        border-bottom-left-radius: $rowBorderRadius;
        border-bottom-right-radius: $rowBorderRadius;

        .cell {
            background: #fff;
            cursor: pointer;
            color: $text-gray-color;
            font-size: 10px;
            line-height: 12px;
            font-weight: normal;
            transition: .2s;
            padding: 3px 15px;
            border-bottom: 1px solid $border-color;
            border-right: 1px solid $border-color;

            &-selected {
                background: #FFF !important;
                color: #000;
            }

            &__content {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;

                span {
                    display: block;
                    text-align: left;
                    width: 100%;
                }
            }

            &__arrow {
                transition: .2s;
                &_rotate {
                    transform: rotate(180deg);
                }
            }
        }

        // .date-cell {
        //     padding: 13px 15px !important;
        // }

        .cell-search {
            padding: 0;
            padding-top: 1px;
            height: 24px;
            box-shadow: 4px 4px 5px 0 rgba(0,0,0, .05);

            input {
                max-width: 100%;
            }

            .cell__search-input {
                ::v-deep input.input::placeholder {
                    color: $placeholder-color;
                }
            }
        }

        .row.search {
            height: 24px;
        }
    }

	.body {
        background: #fff;
        &__empty {
            margin-top: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
        }
        .row {
            height: 50px;
            border-radius: $rowBorderRadius;
            transition: .25s;

            .cell {
                color: #000;
                font-size: 12px;
                line-height: 14px;
                background: #fff;
                padding: 13px 15px;
                border-bottom: 1px solid $border-color;
                border-right: 1px solid $border-color;
                transition: .25s;
            }

            &:hover {
                .cell {
                    background: #FFF !important;
                }
            }
            &__no-margin {
                margin-bottom: 0px;
            }
        }
    }

    .row {
        display: flex;
        width: max-content;
    }

	.cell {
        position: relative;
        background: #fff;
        flex-grow: 0;
        flex-shrink: 0;
        width: 200px;
        min-width: 50px;
        padding: 2px 15px 2px 0;
        display: flex;
        align-items: center;

        &-selected {
            background: #FFF !important;
        }

        &-checkbox {
            width: 50px;
            display: flex; align-items: center; justify-content: center;

            &.isSticky {
                position: sticky;
                left: 0px;
                z-index: 1;
                box-shadow: rgba(0, 0, 0, 0.05) 5px 0px 5px 0px;
            }

            label {
                cursor: pointer;

                input {
                    display: none;

                    &:checked + span {
                        border-color: $active-color;
                        background-color: $active-color;
                        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
                        background-size: 60%;
                        background-position: center;
                        background-repeat: no-repeat;
                    }
                }

                span {
                    width: 24px;
                    height: 24px;
                    border: 2px solid;
                    border-color: #ccc;
                    display: block;
                    border-radius: 4px;
                    transition: border-color 250ms;

                    &:hover {
                        border-color: $active-color;
                    }
                }
            }
        }
    }
}
</style>
